@tailwind base;
@tailwind components;
@tailwind utilities;

.spotlight {
  position: fixed;
  border-radius: 550%;
  pointer-events: none;
  box-shadow: 0 0 500px 500px rgba(7, 0, 100, 0.5);
  transform: translate(-50%, -50%);
}

.hover-bg-transparent-50:hover {
  background-color: rgba(26, 40, 72, 0.5);
}

.App {
  text-align: center;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.App-header {
  height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.background-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
}

.background-container .inverted-canvas {
  transform: scaleX(-1);
}

.spotlight {
  position: fixed;
  border-radius: 550%;
  pointer-events: none;
  box-shadow: 0 0 200px 200px rgba(4, 0, 65, 0.5);
  transform: translate(-50%, -50%);
  z-index: 2;
}

.content {
  position: relative;
  z-index: 3;
  padding: 20px;
  color: white;
}

.margin-top-50 {
  margin-top: 50px !important;
}


.underline-animation {
  position: relative;
  display: inline-block;
}

.underline-animation::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  display: block;
  margin-top: 0px;
  right: 0;
  background: white;
  transition: width 0.4s ease;
}

.group:hover .underline-animation::after {
  width: 100%;
  left: 0;
  background: white;
}


.radial-gradient {
  background: rgb(0, 0, 0);
}

.linear-mask {
  mask-image: linear-gradient(
      -75deg,
      white calc(var(--x) + 20%),
      transparent calc(var(--x) + 30%),
      white calc(var(--x) + 100%)
  );
  -webkit-mask-image: linear-gradient(
      -75deg,
      white calc(var(--x) + 20%),
      transparent calc(var(--x) + 30%),
      white calc(var(--x) + 100%)
  );
}

.linear-overlay {
  background-image: linear-gradient(
      -75deg,
      rgba(var(--overlay-color), 0.1) calc(var(--x) + 20%),
      rgba(var(--overlay-color), 0.5) calc(var(--x) + 25%),
      rgba(var(--overlay-color), 0.1) calc(var(--x) + 100%)
  );
  mask:
      linear-gradient(black, black) content-box,
      linear-gradient(black, black);
  -webkit-mask:
      linear-gradient(black, black) content-box,
      linear-gradient(black, black);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
}


@keyframes featherEffect {
  0% {
    box-shadow: 0 0 0 0 rgba(69, 0, 180, 0.5);
  }
  100% {
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0);
  }
}

.feather-effect {
  animation: featherEffect 0.70s forwards;
}

/* App.css */
.pdf-container {
  height: 100vh;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .pdf-container {
    height: 100vh;
    width: 100%;
    padding: 10px;
  }
  .react-pdf__Document {
    width: 100% !important;
  }
}

@media (max-width: 480px) {
  .pdf-container {
    height: 100vh;
    width: 100%;
    padding: 5px;
  }
  .react-pdf__Document {
    width: 100% !important;
  }
}
